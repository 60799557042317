<template>
	<v-col cols="12">
		<v-card elevation="0" class="pl-5 pr-5 mt-2" color="transparent">
			<v-row>
				<v-col>
					<v-card elevation="0" class="mb-5" color="transparent">
						<v-card-title class="button font-weight-medium pa-0 blue-grey--text text--darken-2">
							LoadBoard
							<span class="ml-1 warning--text font-weight-bold">New Load</span>
						</v-card-title>
						<v-card-title class="mt-3 pb-0">
							<h5 class="light-blue--text text--darken-1">Load - ( unique id 21451 )</h5>
							<v-spacer></v-spacer>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning" width="100">Generate</v-btn>
							<v-btn class="ma-0 caption font-weight-bold mr-2" tile dark color="warning" width="100">Back</v-btn>
						</v-card-title>
					</v-card>
				</v-col>
			</v-row>
			<v-card elevation="1" class="pl-0 pr-0 pt-2 pb-10">
				<v-container fill-height>
					<!-- page icon -->
					<PageIcon background-color="teal darken-1">
						<v-icon x-large>mdi-archive-arrow-down-outline</v-icon>
					</PageIcon>
					<!-- <form action="" class="container">
						<AddTruck
							ref="loadboard-add-truck"
							:truckInfo="truckInfo"
							:truckFields="truckFields"
							:truckFieldsOptional="truckFieldsOptional"
							:initialLocationFields="initialLocationFields"
						></AddTruck>
					</form> -->
					<v-row class="mt-4">
						<v-col cols="12" md="4">
							<v-card outlined elevation="0" class="mx-2">
								<v-card-title class="body-1 font-weight-bold light-blue--text text--darken-1">
									Lead information - <span class="warning--text">13</span>
								</v-card-title>
								<v-card-text class="mt-1 pb-4">
									<v-row>
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Name: </v-col>
										<v-col class="blue-grey--text text--darken-1" cols="12" md="7"> John S. Doe </v-col>
									</v-row>
									<v-row>
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Email: </v-col>
										<v-col class="blue-grey--text text--darken-1" cols="12" md="7"> johndoe@gmail.com </v-col>
									</v-row>
									<v-row>
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Home Phone: </v-col>
										<v-col class="blue-grey--text text--darken-1" cols="12" md="7"> 01 234 5678 </v-col>
									</v-row>
									<v-row>
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Mobile Phone: </v-col>
										<v-col class="blue-grey--text text--darken-1" cols="12" md="7"> 01 234 5678 </v-col>
									</v-row>
									<v-row>
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Work Phone: </v-col>
										<v-col class="blue-grey--text text--darken-1" cols="12" md="7"> 01 234 5678 </v-col>
									</v-row>
								</v-card-text>
								<v-card-text class="mt-1 pb-4">
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Move Type: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Local Residential </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Mileage: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> 2131 </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Approx Move Date: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> (mm/day/year) </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Additional Stop: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> 0 </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Parking Area: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Yes </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Parking Area: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Long Cary(Loading or Unloading) </v-col>
									</v-row>
								</v-card-text>
								<v-card-text class="mt-1 pb-4">
									<h6 class="body-1 font-weight-bold mb-3 blue-grey--text text--darken-2">Origin</h6>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Pick-up Address: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Room 212 Street </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Floor: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> 1st Floor </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> City: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Los Angeles </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> State: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> California </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Zipcode: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> 9011 </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Parking Area: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Long Cary(Loading or Unloading) </v-col>
									</v-row>
								</v-card-text>
								<v-card-text class="mt-1 pb-4">
									<h6 class="body-1 font-weight-bold mb-3 blue-grey--text text--darken-2">Destination</h6>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Pick-up Address: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Room 212 Street </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Floor: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> 1st Floor </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> City: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Los Angeles </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> State: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> California </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Zipcode: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> 9011 </v-col>
									</v-row>
									<v-row class="caption">
										<v-col class="blue-grey--text text--darken-2 font-weight-bold" cols="12" md="5"> Parking Area: </v-col>
										<v-col class="blue-grey--text text--darken-1 warning--text font-weight-bold" cols="12" md="7"> Long Cary(Loading or Unloading) </v-col>
									</v-row>
								</v-card-text>
								<v-card-text class="mb-2">
									<h2 class="light-blue--text text--darken-1">Total Weight - <span class="warning--text">4,524</span></h2>
								</v-card-text>
							</v-card>
						</v-col>
						<v-col cols="12" md="8">
							<v-card elevation="0" outlined>
								<v-app-bar dark color="light-blue darken-3" elevation="0" tile dense>
									<v-toolbar-title class="font-weight-bold body-1">Assigned</v-toolbar-title>

									<v-spacer></v-spacer>
								</v-app-bar>
								<v-card-text>
									<v-data-table hide-default-footer :headers="assignedHeaders" :items="assignedInfo" class="elevation-0">
										<template v-slot:body="{ items }">
											<tr v-for="assigned in items" :key="assigned.id">
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">{{ assigned.type }}</td>
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">{{ assigned.name }}</td>
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">
													<v-btn v-if="assigned.action" class="text-capitalize caption font-weight-bold">Send Email </v-btn>
												</td>
												<td class="pt-1">
													<v-text-field background-color="grey lighten-4" flat dense light solo hide-details></v-text-field>
												</td>
												<td class="text-center">
													<v-btn color="error" class="ma-2 text-capitalize caption font-weight-bold">Un-assigned </v-btn>
												</td>
											</tr>
										</template>
									</v-data-table>
								</v-card-text>
							</v-card>
							<v-card elevation="0" outlined>
								<v-app-bar dark color="light-blue darken-3" elevation="0" tile dense>
									<v-toolbar-title class="font-weight-bold body-1">Truck Available</v-toolbar-title>

									<v-spacer></v-spacer>
								</v-app-bar>
								<v-card-text>
									<v-data-table hide-default-footer :headers="assignedHeaders" :items="assignedInfo" class="elevation-0">
										<template v-slot:body="{ items }">
											<tr v-for="assigned in items" :key="assigned.id">
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">{{ assigned.type }}</td>
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">{{ assigned.name }}</td>
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">
													<v-btn v-if="assigned.action" class="text-capitalize caption font-weight-bold">Send Email </v-btn>
												</td>
												<td class="pt-1">
													<v-text-field background-color="grey lighten-4" flat dense light solo hide-details></v-text-field>
												</td>
												<td class="text-center">
													<v-btn color="error" class="ma-2 text-capitalize caption font-weight-bold">Un-assigned </v-btn>
												</td>
											</tr>
										</template>
									</v-data-table>
								</v-card-text>
							</v-card>
							<v-card elevation="0" outlined>
								<v-app-bar dark color="light-blue darken-3" elevation="0" tile dense>
									<v-toolbar-title class="font-weight-bold body-1">Crew Available</v-toolbar-title>

									<v-spacer></v-spacer>
								</v-app-bar>
								<v-card-text>
									<v-data-table hide-default-footer :headers="assignedHeaders" :items="assignedInfo" class="elevation-0">
										<template v-slot:body="{ items }">
											<tr v-for="assigned in items" :key="assigned.id">
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">{{ assigned.type }}</td>
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">{{ assigned.name }}</td>
												<td class="py-3 caption font-weight-bold blue-grey--text text--darken-2">
													<v-btn v-if="assigned.action" class="text-capitalize caption font-weight-bold">Send Email </v-btn>
												</td>
												<td class="pt-1">
													<v-text-field background-color="grey lighten-4" flat dense light solo hide-details></v-text-field>
												</td>
												<td class="text-center">
													<v-btn color="error" class="ma-2 text-capitalize caption font-weight-bold">Un-assigned </v-btn>
												</td>
											</tr>
										</template>
									</v-data-table>
								</v-card-text>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-card>
		</v-card>
	</v-col>
</template>

<script>
const AddTruck = () => import(/* webpackChunkName: 'loadboard add truck'*/ "Components/loadboard/LoadboardAddTruck");
import PageIcon from "Components/UtilityUI/UtilityUIPageIcon";
export default {
	name: "NewLoad",
	components: {
		// AddTruck,
		PageIcon
	},
	data() {
		return {
			assignedHeaders: [
				{
					text: "Type",
					class: "text-uppercase"
				},
				{
					text: "Name",
					class: "text-uppercase"
				},
				{
					text: "Action",
					class: "text-uppercase"
				},
				{
					text: "Comments",
					class: "text-uppercase"
				},
				{
					text: ""
				}
			],
			assignedInfo: [
				{
					id: 1,
					type: "Truck",
					name: "22 Foot Truck",
					action: false,
					comments: ""
				},
				{
					id: 2,
					type: "Crew",
					name: "John Doe (Driver)",
					action: true,
					comments: ""
				},
				{
					id: 3,
					type: "Crew",
					name: "John Doe (Laborer)",
					action: true,
					comments: ""
				}
			]
			// key: value
		};
	}
};
</script>

<style>
</style>